<template>
  <v-row class="d-flex justify-center mt-0 mx-md-2 mx-n1 mb-0">
    <v-col cols="12" class="d-flex justify-end align-center pt-0 px-1">
      <div
        v-text="nuevo ? 'Nuevo recibo en efectivo' : `Recibo Nº ${id}`"
        style="font-size: 20px"
      ></div>
      <v-spacer></v-spacer>
      <v-expand-x-transition>
        <v-switch
          v-if="!nuevo && existe && permiso"
          v-model="editar"
          label="Editar"
          class="d-flex align-center py-0 my-0"
          hide-details
          dense
        ></v-switch>
      </v-expand-x-transition>
    </v-col>
    <v-card>
      <v-col cols="12">
        <v-form ref="form">
          <v-row class="pt-4">
            <v-col cols="6" sm="3" md="2" class="py-0">
              <v-autocomplete
                v-model="recibo.empresa"
                label="Empresa"
                item-text="nombre_corto"
                item-value="id"
                tabindex="1"
                :rules="editar ? [rules.required, rules.notdef] : []"
                :items="empresas"
                :readonly="!nuevo || !editar"
                :filled="!nuevo || !editar"
                validate-on-blur
                outlined
                dense
                @change="get_locales(); get_marca()"
              ></v-autocomplete>
            </v-col>
            <v-col v-if="recibo.empresa != null" cols="6" sm="3" lg="2" class="py-1">
              {{ empresas.find(em => em.id == recibo.empresa).nombre }}
            </v-col>
            <v-col cols="6" md="2" class="py-0">
              <v-autocomplete
                v-model="recibo.sucursal"
                label="Sucursal"
                item-text="nombre"
                item-value="id"
                tabindex="1"
                :rules="editar ? [rules.required, rules.notdef] : []"
                :items="sucursales"
                :readonly="!nuevo || !editar"
                :filled="!nuevo || !editar"
                validate-on-blur
                outlined
                dense
                @change="get_locales()"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="6" md="4" class="py-0">
              <v-autocomplete
                v-model="recibo.ptovta"
                label="Punto de venta"
                item-text="nombre"
                item-value="id"
                tabindex="1"
                :rules="editar ? [rules.required, rules.notdef] : []"
                :items="locales.filter(loc => loc.sucursal == recibo.sucursal && loc.empresa == recibo.empresa)"
                :readonly="!nuevo || !editar"
                :filled="!nuevo || !editar"
                outlined
                dense
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="6" md="3" class="py-0">
              <v-text-field
                v-model.trim="recibo.nombre"
                label="Nombre"
                tabindex="1"
                :rules="editar ? [rules.required] : []"
                :readonly="!editar"
                :filled="!editar"
                validate-on-blur
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="3" class="py-0">
              <v-text-field
                v-model.trim="recibo.apellido"
                label="Apellido"
                tabindex="1"
                :rules="editar ? [rules.required] : []"
                :readonly="!editar"
                :filled="!editar"
                validate-on-blur
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="6" sm="3" md="2" class="py-0">
              <v-autocomplete
                v-model="recibo.tipo_doc"
                label="Tipo documento"
                item-text="nombre"
                item-value="id"
                tabindex="1"
                :rules="editar ? [rules.required] : []"
                :items="tipos_doc"
                :readonly="!editar"
                :filled="!editar"
                validate-on-blur
                outlined
                dense
              ></v-autocomplete>
            </v-col>
            <v-col cols="6" sm="3" md="3" class="py-0">
              <v-text-field
                v-model.trim="recibo.num_doc"
                label="Nº Documento"
                tabindex="1"
                :rules="editar ? [rules.required] : []"
                :readonly="!editar"
                :filled="!editar"
                validate-on-blur
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="2" class="py-0">
              <v-text-field
                v-model.trim="recibo.telefono"
                v-mask="'###############'"
                label="Teléfono"
                tabindex="1"
                type="number"
                :readonly="!editar"
                :filled="!editar"
                validate-on-blur
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4" class="py-0">
              <v-text-field
                v-model.trim="recibo.email"
                label="Email"
                tabindex="1"
                :rules="editar ? (recibo.email ? [rules.email] : []) : []"
                :readonly="!editar"
                :filled="!editar"
                validate-on-blur
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="6" sm="3" md="2" class="py-0">
              <v-autocomplete
                v-model="recibo.marca"
                label="Marca"
                item-text="nombre"
                item-value="id"
                tabindex="1"
                :rules="editar && marcas_emp.filter(ma => ma.empresas.find(emp => emp == recibo.empresa)).length > 0 ? [rules.required] : []"
                :items="marcas_emp.filter(ma => ma.empresas.find(emp => emp == recibo.empresa))"
                :readonly="!editar"
                :filled="!editar"
                validate-on-blur
                outlined
                dense
              ></v-autocomplete>
            </v-col>
            <v-col cols="6" sm="3" md="2" class="py-0">
              <v-text-field
                v-model.trim="recibo.sol_ad"
                label="Solicitud adhesión"
                tabindex="1"
                :rules="editar ? [rules.required] : []"
                :readonly="!editar"
                :filled="!editar"
                validate-on-blur
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="6" sm="3" md="2" class="py-0">
              <v-text-field
                v-model.trim="recibo.importe"
                label="Importe"
                type="number"
                prefix="$"
                tabindex="1"
                :rules="editar ? [rules.required] : []"
                :readonly="!editar"
                :filled="!editar"
                validate-on-blur
                outlined
                dense
              ></v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-col>
    </v-card>
    <v-col cols="12" class="d-flex justify-end">
      <v-btn
        color="secondary"
        @click="cerrar()"
      >
        Volver
      </v-btn>
      <BtnConfirmar
        v-if="editar"
        clase="ml-3"
        @action="guardar()"
      />
    </v-col>

    <PDFViewer
      v-model="dialog_pdf"
      :pdf="pdf"
      :nombre="pdf_nombre"
      :titulo="pdf_titulo"
    />

  </v-row>
</template>

<script>
import { mapState } from 'vuex'
import BtnConfirmar from '../../components/util/BtnConfirmar'
import { reciboEfDycarA4 } from '../../util/plantillas/pdfs'
import PDFViewer from '../../util/plantillas/PDFViewer'

export default {
  data () {
    return {
      id: this.$route.params.id,
      editar: false,
      nuevo: false,
      existe: false, // variable para controllar el boton guardar en caso que no exista el recibo
      permiso: false,
      dialog_pdf: false,
      pdf: null,
      pdf_nombre: '',
      pdf_titulo: '',
      tipos_doc: [],
      recibo_original: {}, // copia de recibo para volver a como estaba cuando cancela la edicion
      recibo: {
        empresa: null,
        sucursal: null,
        ptovta: null,
        nombre: null,
        apellido: null,
        tipo_doc: 2,
        num_doc: null,
        telefono: null,
        email: null,
        sol_ad: null,
        importe: null,
        estado: 1,
        marca: null
      },
      rules: {
        required: value => !!value || 'Campo requerido',
        notdef: value => value !== -1 || 'Campo requerido',
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'Email no válido'
        },
      }
    }
  },
  created () {
    this.get_data()
    if (this.id) {
      this.get_recibo()
    } else {
      this.editar = true
      this.nuevo = true
    }
  },
  computed: {
    ...mapState(['empresas', 'sucursales', 'locales']),
    ...mapState('dycar', ['marcas_emp'])
  },
  components: {
    BtnConfirmar,
    PDFViewer,
  },
  watch: {
    editar (val) {
      if (val) {
        this.$refs.form.resetValidation()
      } else {
        // vuelve al estado originial
        this.recibo = Object.assign({}, this.recibo_original)
      }
    }
  },
  methods: {
    async guardar () {
      if (this.$refs.form.validate()) {
        this.$store.state.loading = true

        if (this.nuevo) {
          await this.$store.dispatch('dycar/nuevo_recibo', this.recibo)
            .then(async (res) => {

              // guarda los datos en la copia del originial
              this.recibo_original = Object.assign({}, this.recibo)

              // mensaje de exito
              this.$store.dispatch('show_snackbar', {
                text: res.message,
                color: 'success',
              })

              // obtiene el recibo que acaba de generar y lo muestra para imprimir
              await this.$store.dispatch('dycar/get_recibo_data_print', res.id)
                .then(async (recibo) => {

                  const data = recibo.data
                  const numero = data.numero

                  // genera el pdf para imprimir
                  this.pdf = await reciboEfDycarA4(data)
                  this.pdf_nombre = `recibo-efectivo-${numero}`
                  this.pdf_titulo = `Recibo en efectivo Nº ${numero}`
                  this.dialog_pdf = true

                })
                .catch(error => {
                  this.$store.dispatch('show_snackbar', {
                    text: error.message,
                    color: 'error',
                  })
                })

            })
            .catch(error => {
              this.$store.dispatch('show_snackbar', {
                text: error.message,
                color: 'error',
              })
            })
        } else {
          await this.$store.dispatch('dycar/editar_recibo', this.recibo)
            .then((res) => {
              // guarda los datos nuevos en la copia del originial
              this.recibo_original = Object.assign({}, this.recibo)

              this.$store.dispatch('show_snackbar', {
                text: res.message,
                color: 'success',
              })
            })
            .catch(error => {
              this.$store.dispatch('show_snackbar', {
                text: error.message,
                color: 'error',
              })
            })
        }

        this.editar = false
        this.$store.state.loading = false
      }
    },
    async get_recibo () {
      this.$store.state.loading = true
      await this.$store.dispatch('dycar/get_recibo', this.id)
        .then(async (res) => {
          this.recibo = res.data
          // guarda una copia
          this.recibo_original = Object.assign({}, this.recibo)

          this.existe = true
          // verifica si tiene permisos para editar
          await this.verificar_permiso()
        })
        .catch(error => {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error',
          })
        })
      this.$store.state.loading = false
    },
    async verificar_permiso () {
      await this.$store.dispatch('dycar/verificar_permiso_edicion', this.recibo.id)
        .then((res) => {
          if (res.permiso == 1) {
            this.permiso = true 
          }
        })
        .catch(error => {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error',
          })
        })
    },
    async get_data () {
      this.$store.state.loading = true
      await this.$store.dispatch('dycar/get_marcas_emp')
      await this.$store.dispatch('get_tipos_doc')
        .then((data) => {
          this.tipos_doc = data.tipos_doc
        })
        .catch(error => {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error',
          })
        })
      this.$store.state.loading = false

      // si solo tiene 1 empresa la selecciona por defecto
      if (this.empresas.length == 1) {
        this.recibo.empresa = this.empresas[0].id
      }
      // si solo tiene 1 sucursal la selecciona por defecto
      if (this.sucursales.length == 1) {
        this.recibo.sucursal = this.sucursales[0].id
      }
    },
    get_locales () {
      let locales = this.locales.filter(loc => loc.sucursal == this.recibo.sucursal && loc.empresa == this.recibo.empresa)
      this.recibo.ptovta = locales.length == 1 ? locales[0].id : null
    },
    get_marca () {
      let marcas = this.marcas_emp.filter(ma => ma.empresas.find(emp => emp == this.recibo.empresa))
      this.recibo.marca = marcas.length == 1 ? marcas[0].id : null
    },
    cerrar () {
      window.close()
    }
  }
}
</script>